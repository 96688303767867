@import url('https://www.nike.com/assets/ncss/3.0/dotcom/desktop/css/ncss.en-us.min.css');

/** HelveticaNeue font */
@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url('./constants/fonts/HelveticaNeue_Font_Family/HelveticaNeue-01.ttf') format('truetype');
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'HelveticaNeue', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTypography-h5, .MuiInputBase-input, a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,
center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,
hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,
strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video {
  font-family: 'HelveticaNeue', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
}
