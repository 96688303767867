.btn-nike-contained {
  height: 32px;
}

button.btn-nike-rounded-black {
  background-color: #000;
  color: #fff;
  border-radius: 25px;
  transition: all 0.2s;
  padding: 7px 18px;
}

button.btn-nike-rounded-black:hover {
  color: #000;
  background-color: #fff;
}

button.nikeButton {
  text-transform: none;
}

.nikeButton.btn-nike-dashed {
  border-style: dashed;
  border-width: 1px;
}