@use '../../constants/color.scss' as *;

.notificationDialog .MuiDialogActions-root {
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.notificationDialog .MuiPaper-root {
  max-width: 560px;
  width: 30%;
  border-radius: 20px;
  padding: 10px;
  
  ::-webkit-scrollbar {
    width: 6px;
  }
   
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}

.massUploadError.uploadTab2 .textContainer .description {
  font-size: 18px;
  line-height: 32px;
  color: $grey9;
}

.massUploadError.uploadTab2 > div > div.MuiPaper-root {
  min-width: 615px;
  border-radius: 20px;
  padding: 10px
}

.massUploadError.uploadTab0 > div > div.MuiPaper-root {
  min-width: 1110px;
  border-radius: 20px;
  padding: 10px
}

.massUploadError.errorImagePopUp > div > div.MuiPaper-root {
  min-width: 615px;
  border-radius: 20px;
  padding: 10px
}
.massUploadError > div > div.MuiPaper-root {
  min-width: 790px;
  border-radius: 20px;
  padding: 10px
}

.notificationDialog .imageContainer img {
  margin: auto;
}

.notificationDialog {
  text-align: center;
}

.notificationDialog .textContainer .title {
  font-size: 42px;
  font-weight: bold;
}

.notificationDialog .textContainer .description {
  font-size: 24px;
  line-height: 32px;
  color: $grey9;
}
.notificationDialog .textContainer .addGacReasonCodeDesc-subtext {
  font-size: 16px;
  line-height: 23px;
}
.notificationDialog .textContainer .failureDescription {
  font-size: 22px;
  line-height: 32px;
  color: $grey9;
  display: grid;
  justify-content: center;
}
.notificationDialog .textContainer .failedDescContainer {
  max-height: 170px;
  overflow: auto;
}
.notificationDialog .textContainer .failedDescContainer .vendorCodeList {
  text-align: left;
}

// .notificationDialog .MuiDialogActions-root {
//   margin-top: 21px;
//   margin-bottom: 32px;
// }
div .btn-nike-rounded-black.nikeButton{
  margin-right: 5px;
}