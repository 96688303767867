@use './../../constants/color.scss' as *;

.loginSpinner .MuiDialogContent-root {
  font-size: 24px;
  color: $grey0
}

.loginNotification .MuiBackdrop-root {
  background: $grey8;
  opacity: 1;
}