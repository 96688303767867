@use '../../constants/color.scss' as *;

.MuiDialog-paper.spinner-dialog {
  button {
    top: 15px
  }
  width: 32.3%;
  align-items: center;
  justify-content: center;
  padding: 30px;
  padding-bottom: 60px;
  border-radius: 20px;
  display: table;
  text-align: center;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active){
   @media all and (max-width: 1280px) {
    .MuiDialog-paper.spinner-dialog {
      width: 59.5%;
      align-items: center;
      justify-content: center;
      padding: 30px;
      padding-bottom: 60px;
      border-radius: 20px;
      display: table;
      text-align: center;
      }
}
  
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.nike-spinner-wrapper {
  display: inline-flex;
  margin: 10% 38%;
  margin-bottom: 30px;
  animation-name: spinner;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @media screen and (max-device-width: 1366px) {
    margin: 10% 32%;
  }
} 
.spinner-text{
  font-size: 24px;
  line-height: 32px;
  color: $grey9;
  text-align: center;
}