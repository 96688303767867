/**
 File contains color variables and classes
 Files can be imported directly in styles.scss wherever required.
*/

$primaryblack: #111111;
$black: #000000;
$grey0: #757575;
$grey1: #8D8D8D;
$grey2: #CCCCCC;
$grey3: #9e9999;
$grey4: #F5F5F5;
$grey5: #FAFAFA;
$grey6: #484848;
$grey7: #6E6E6E;
$grey8: #DCDCDC;
$disabledGrey: #b9b9b9;
$white: #FFFFFF;
$blue: #1D6CCB;
$lightBlue: #E8F2FF;
$error: #FE0000;
$success: #3CA023;
$accent: #FA5400;
$blue1:#0075BA;
$blue2: #E4F2FE;
$blue3: #2196F3 ;
$blue1Transparent: #D6E4FF;
$grey9: #8c8c8c;
$grey10: #e5e5e5;
$grey11: #c4c4c4;
$green: #43A047;
$green1: #008000;
$red: #D90007;
$red1: #f44336;
$red2: #FE0000;
$lightRed: #f8a5a5;



// $black: #000000;
// $grey0: #757575;
// $blue: #1D6CCB;
// $error: #FE0000;
// $success: #3CA023;
// $accent: #FA5400;